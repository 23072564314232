import XLSX from "xlsx/dist/xlsx.full.min.js";

// export const searchFunction = (allData, searchValue) => {
// 	const keys = Object.keys(allData[0]);
// 	const result = [];
// 	allData.forEach((item) => {
// 		keys.forEach((key) => {
// 			if (item[key]?.toString().includes(searchValue)) {
// 				result?.push(item);
// 			}
// 		});
// 	});
// 	return result;
// };

export const searchFunction = (
  allData,
  searchValue,
  searchColumns,
  nest = null
) => {
  const result = [];
  allData.forEach((item) => {
    if (nest) {
      let itm = item[nest];
      for (let key in itm) {
        if (searchColumns?.includes(key)) {
          if (
            itm[key]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          ) {
            result?.push(item);
            break;
          }
        }
      }
    } else {
      for (let key in item) {
        if (searchColumns?.includes(key)) {
          if (
            item[key]
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          ) {
            result?.push(item);
            break;
          }
        }
      }
    }
  });
  return result;
};

// export to excel function
const exportMe = (data) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  return blob;
};

export const exportToExcel = (data) => {
  const blob = exportMe(data);
  const filename = "data.xlsx";
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
};
