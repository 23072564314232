import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import SignIn from "./Modules/Authentication/SignIn/SignIn";
import ForgotPassword from "./Modules/Authentication/ForgotPassword/ForgotPassword";
import OTP from "./Modules/Authentication/OTP/OTP";
import ChangePassword from "./Modules/Authentication/ChangePassword/ChangePassword";
import UsersPage from "./Modules/Users/Users";
import UserSurvey from "./Modules/Users/UserSurvey";
import AdminsPage from "./Modules/Admins/Admins";
import Dashboard from "./Modules/Dashboard/Dashboard";
import { useSelector } from "react-redux";
import Sheikhs from "./Modules/Sheikhs/Sheikh";
import SheikhList from "./Modules/Sheikhs/SheikhList";
import EndedLectureWithAttenders from "./Modules/Lectures/EndedLectureWithAttenders";
import EndedLectures from "./Modules/Lectures/EndedLectures";
import GroupsPage from "./Modules/Groups/Groups";
import AddGroup from "./Modules/Groups/AddGroup";
import AssignToGroup from "./Modules/Groups/AsignTOGroup";
import GroupUsersPage from "./Modules/Groups/GroupUsers";
import PresentStudents from "./Modules/Users/PresentStudents";
import NumberOfAttendence from "./Modules/Lectures/NumberOfAttendence";

//import logout from "./Modules/Authentication/LogOut/logout";
// import Packs from "./Modules/Packs/Packs";
// import Chicapa from "./Modules/Chicapa/Chicapa";
// import Packages from "./Modules/Packages/Packages";
// import Settings from "./Modules/Settings/Settings";
function Router() {
  const { authorization } = useSelector((state) => state.authorization);
  const isAuth = Object.keys(authorization).length > 0;
  //console.log("isAuth",isAuth)
  return (
    <Routes>
      <Route
        path="/"
        element={isAuth ? <Navigate to="/users" /> : <SignIn />}
      />
      {/* <Route
				path="/logout"
				element={<Navigate to="/logout" />}
			/> */}
      <Route
        path="/forgotPassword"
        element={isAuth ? <Navigate to="/users" /> : <ForgotPassword />}
      />
      <Route
        path="/OTP"
        element={isAuth ? <Navigate to="/users" /> : <OTP />}
      />
      <Route
        path="/changePassword"
        element={isAuth ? <Navigate to="/users" /> : <ChangePassword />}
      />
      <Route
        path="/users"
        element={isAuth ? <UsersPage /> : <Navigate to="/" />}
      />
      <Route
        path="/present-students"
        element={isAuth ? <PresentStudents /> : <Navigate to="/" />}
      />
      <Route
        path="/admins"
        element={isAuth ? <AdminsPage /> : <Navigate to="/" />}
      />
      <Route
        path="/dashboard"
        element={isAuth ? <Dashboard /> : <Navigate to="/" />}
      />
      <Route
        path="/sheikhs"
        element={isAuth ? <Sheikhs /> : <Navigate to="/" />}
      />
      <Route
        path="/sheikhList"
        element={isAuth ? <SheikhList /> : <Navigate to="/" />}
      />
      <Route
        path="/endedLectures"
        element={isAuth ? <EndedLectures /> : <Navigate to="/" />}
      />
      <Route
        path="/endedLectureWithAttenders"
        element={isAuth ? <EndedLectureWithAttenders /> : <Navigate to="/" />}
      />
      <Route
        path="//number-of-attendence"
        element={isAuth ? <NumberOfAttendence /> : <Navigate to="/" />}
      />

      <Route
        path="/groups"
        element={isAuth ? <GroupsPage /> : <Navigate to="/" />}
      />
      <Route
        path="/survey"
        element={isAuth ? <UserSurvey /> : <Navigate to="/" />}
      />
      <Route
        path="/addGroup"
        element={isAuth ? <AddGroup /> : <AddGroup to="/" />}
      />
      <Route
        path="/assignToGroup"
        element={isAuth ? <AssignToGroup /> : <AssignToGroup to="/" />}
      />
      <Route
        path="/groupUsers"
        element={isAuth ? <GroupUsersPage /> : <GroupUsersPage to="/" />}
      />

      {/* <Route
        path="/survey"
        render={(props) =>
          isAuth  ? (
            <UserSurvey {...props} />
          ) : (
            <Navigate to="/" />
          )
        }
      /> */}
      {/* <Route
				path="/transactions"
				element={isAuth ? <Transactions /> : <Navigate to="/" />}
			/>
			<Route path="/home" element={isAuth ? <Home /> : <Navigate to="/" />} />{" "} */}
    </Routes>
  );
}

export default Router;
