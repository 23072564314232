import { useState } from "react";
import { Spin, Row, Col, Input } from "antd";
import { Formik } from "formik";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import lock from "../../../Assets/Icons/lock.svg";
import openedLock from "../../../Assets/Icons/lock-opened.svg";
import FloatInput from "../../Common/FloatInput/FloatInput";
import { resetPassword } from "../../../Network/Network";
import { useNavigate, useLocation } from "react-router-dom";
import { toastSuccess } from "../../../Helpers/toasters";
function ChangePassword() {
	const [loading, updateLoading] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	let email =
		location.search.split("email=").length > 0 &&
		location.search.split("email=")[1];
	const handleChangePasswords = (values) => {
		updateLoading(true);
		let data = {
			email,
			password: values.newPassword,
		};
		resetPassword(data, (success) => {
			if (success.success) {
				navigate("/");
				toastSuccess(success.message);
			}
		});
	};
	return (
		<div className="changePassword">
			<Row>
				<Col xs={24} md={12} className="solidArea"></Col>
				<Col className="d-flex flex-column flex-1 align-items-center justify-content-center">
					<Formik
						initialValues={{ newPassword: "", confirmPassword: "" }}
						validate={(values) => {
							const errors = {};
							if (!values.newPassword) {
								errors.newPassword = "Required";
							}
							if (!values.confirmPassword) {
								errors.confirmPassword = "Required";
							}
							if (values.newPassword && values.confirmPassword) {
								if (values.newPassword !== values.confirmPassword) {
									errors.passwordsNotMatch = "Passwords don't match";
								}
							}
							return errors;
						}}
						onSubmit={(values, { setSubmitting }) => {
							handleChangePasswords(values);
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
							/* and other goodies */
						}) => (
							<form
								onSubmit={handleSubmit}
								className={
									"mt-4 d-flex flex-column flex-1 align-items-center justify-content-center width-60"
								}
							>
								<h2 className="mainTitle mb-4 fw-700">Reset Password</h2>
								<FloatInput
									label="Password"
									name="newPassword"
									value={values.newPassword}
								>
									<Input.Password
										name="newPassword"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.newPassword}
										iconRender={(visible) =>
											visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
										}
										prefix={<img src={openedLock} alt="lock" />}
										className={`mb-2 mt-4  primary-input ${
											errors.newPassword &&
											touched.newPassword &&
											`border  border-danger`
										}`}
									/>
								</FloatInput>
								<FloatInput
									label="Confirm Password"
									name="confirmPassword"
									value={values.confirmPassword}
								>
									<Input.Password
										name="confirmPassword"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.confirmPassword}
										iconRender={(visible) =>
											visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
										}
										prefix={<img src={lock} alt="lock" />}
										className={`mb-2 mt-4  primary-input ${
											errors.confirmPassword &&
											touched.newPassword &&
											`border  confirmPassword`
										}`}
									/>
								</FloatInput>
								<div className="text-danger mb-4">
									{errors.passwordsNotMatch}
								</div>
								<button disabled={loading} className="primary_btn mt-4">
									{loading ? <Spin /> : "Confirm"}
								</button>
							</form>
						)}
					</Formik>
				</Col>
			</Row>
		</div>
	);
}

export default ChangePassword;
