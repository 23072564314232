import { useState, useEffect, useRef } from "react";
import { exportToExcel, searchFunction } from "../../Helpers/helpers";
import { SearchOutlined } from "@ant-design/icons";
import NavBar from "../Common/NavBar/NavBar";
import SideBar from "../Common/SideBar/SideBar";
import { getActivelectures } from "../../Network/Network";
import { Button, Input, Space } from "antd";
import { useNavigate } from "react-router-dom";
import Grid from "../Common/Grid/Grid";
import moment from "moment";

function EndedLecturesWithAttenders() {
  // const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(1);
  const [newArr, setNewArr] = useState([]);
  // const [pageNumber, setPageNumber] = useState(1);
  const [tableLoading, setTableLoading] = useState(true);
  const [dataSource, updateDataSource] = useState([]);
  const [allDataSource, updateAllDataSource] = useState([]);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [searchValue, updateSearchValue] = useState("");
  let [dateFrom, setDateFrom] = useState(null);
  let [dateTo, setDateTo] = useState(null);
  const [searchedColumn, updateSearchedColumn] = useState("");
  const navigate = useNavigate();
  let searchInput = useRef(null);

  useEffect(() => {
     getLecturesData();
    // eslint-disable-next-line
  }, [dateFrom, dateTo]);

  // useEffect(() => {
  //   getActivelectures(
  //     (success) => {
  //       if (success?.isSuccess) {
  //         let newArray = newArr.concat(success.data);
  //         setNewArr(newArray);
  //         if (success.data.length === 1000) {
  //           setCount((prev) => prev + 1);
  //         } else {
  //           setTableLoading(false);
  //         }
  //       }
  //     },
  //     (fail) => {},
  //     { from: dateFrom, to: dateTo, page: count }
  //   ); //eslint-disable-next-line
  // }, [count, dateFrom, dateTo]);

  useEffect(() => {
    let searchColumns = [
      "description",
      "category",
      "approvedAttending",
      "sheikh",
      "from",
      "to",
      "notes",
    ];
    let searchResult = searchFunction(
      allDataSource,
      searchValue,
      searchColumns
    );
    updateDataSource(searchResult);
    setFilteredDataSource(searchResult);
    // eslint-disable-next-line
  }, [searchValue]);

  const getLecturesData = () => {
    setTableLoading(true);
    getActivelectures(
      (success) => {
        if (success?.isSuccess) {
          updateDataSource(success.data);
          updateAllDataSource(success.data);
          setFilteredDataSource(success.data);
          setTableLoading(false);
        }
      },
      (fail) => {},
      { from: dateFrom, to: dateTo }
    );
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    updateSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    updateSearchedColumn("");
    if (dataSource?.length) {
      setFilteredDataSource(dataSource);
    }
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              // updateSearchText(selectedKeys[0]);
              updateSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex
        ? // <Highlighter
          // 	highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          // 	searchWords={[searchText]}
          // 	autoEscape
          // 	textToHighlight={text ? text.toString() : ""}
          // />
          text
        : text,
  });
  function changeDate(date) {
    if (date) {
      setDateFrom(date[0]);
      setDateTo(date[1]);
    } else {
      setDateFrom(null);
      setDateTo(null);
    }
  }
  const columns = [
    {
      title: "بداية المحاضرة",
      key: "from",
      dataIndex: "from",
      render: (_, record) => {
        return (
          <span dir="ltr">
            {moment(record?.from).format("YYYY-MM-DD HH:mm:ss")}
          </span>
        );
      },
      sortDirections: ["descend"],
    },
    {
      title: "نهاية المحاضرة ",
      key: "to",
      dataIndex: "to",
      render: (_, record) => {
        return (
          <span dir="ltr">
            {moment(record?.to).format("YYYY-MM-DD HH:mm:ss")}
          </span>
        );
      },
    },
    {
      title: "وصف المحاضرة",
      key: "description",
      dataIndex: "description",
      ...getColumnSearchProps("description"),
      onFilter: (value, record) => record.description.indexOf(value) === 0,
    },
    {
      title: "المعلم",
      key: "sheikh",
      dataIndex: "sheikh",
      ...getColumnSearchProps("sheikh"),
      onFilter: (value, record) => record?.sheikh.indexOf(value) === 0,
    },
    {
      title: "عدد الحاضرين",
      key: "noOfAttenders",
      dataIndex: "noOfAttenders",
      sorter: (a, b) => a.noOfLectures - b.noOfLectures,
      sortDirections: ["descend"],
      render: (_, record) => (
        <span role="button" onClick={() => handleAttendersOnClick(record)}>
          {record?.noOfAttenders}
        </span>
      ),
    },
  ];

  const handleAttendersOnClick = (record) => {
    navigate("/present-students", { state: { lectureId: record?._id } });
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setFilteredDataSource(extra?.currentDataSource);
    updateDataSource(extra?.currentDataSource);
  };

  const handleExportToExcel = () => {
    let filteredData = [];
    filteredDataSource.map((lec) =>
      filteredData.push({
        From: `${lec?.from.substring(0, 10)} ${lec?.from.substring(11, 16)}`,
        To: `${lec?.to.substring(0, 10)} ${lec?.to.substring(11, 16)}`,
        Description: lec?.description,
        Sheikh: lec?.sheikh,
        "Number Of Attenders": lec?.noOfAttenders,
      })
    );
    const data = filteredData;
    exportToExcel(data);
  };
  return (
    <div className="d-flex">
      <SideBar parent={"lectureWithAttenders"} />
      <div className="flex-1 section">
        <NavBar
          getSearchedValue={(value) => {
            updateSearchValue(value);
          }}
        />
        <Grid
          mainTitle={" الحضور"}
          subTitle={"كل الحضور"}
          columns={columns}
          //dataSource={newArr}
          dataSource={dataSource}
          loading={tableLoading}
          rowKey={"_id"}
          exportMe={true}
          options={true}
          searchFromTo={true}
          exportToExcel={handleExportToExcel}
          changeDate={changeDate}
          onChange={handleTableChange}
          emptyTableText={{
            emptyText: "لا توجد محاضرات",
          }}
          // setPageNumber={setPageNumber}
          // pageNumber={pageNumber}
          // recordsByPage={1000}
        />
      </div>
    </div>
  );
}

export default EndedLecturesWithAttenders;
