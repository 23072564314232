import { useState, useEffect } from "react";
import { searchFunction } from "../../Helpers/helpers";
import Grid from "../Common/Grid/Grid";
import NavBar from "../Common/NavBar/NavBar";
import SideBar from "../Common/SideBar/SideBar";
//import moment from "moment";
import {
  addAdmins,
  ChangeAdminStatus,
  getAllAdmins,
} from "../../Network/Network";
import { Input, Button } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import lock from "../../Assets/Icons/lock.svg";
import call from "../../Assets/Icons/call.svg";
import { toastSuccess } from "../../Helpers/toasters";

function Admins() {
  const [mode, updateMode] = useState("");
  const [dataSource, updateDataSource] = useState([]);
  const [allDataSource, updateAllDataSource] = useState([]);
  const [rerender, updateRerender] = useState(false);
  const [searchValue, updateSearchValue] = useState("");
  const [phone, updatePhone] = useState("");
  const [password, updatePassword] = useState("");
  const [fullName, updateFullName] = useState("");
  const [loading, updateLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    getAdmins();
    // eslint-disable-next-line
  }, [rerender]);

  useEffect(() => {
    // setTableLoading(true);
    const searchColumns = ["fullName", "phone"];
    const searchedDataSource = searchFunction(
      allDataSource,
      searchValue,
      searchColumns
    );
    updateDataSource(searchedDataSource);
    // setTableLoading(false);
    // eslint-disable-next-line
  }, [searchValue]);

  const getAdmins = () => {
    getAllAdmins((success) => {
      if (success?.isSuccess) {
        updateDataSource(success.data);
        updateAllDataSource(success.data);
        setTableLoading(false);
      }
    });
  };

  const changeAdminStatusFunction = (userId, isActive) => {
    let data = {
      adminId: userId,
      isActive,
    };
    ChangeAdminStatus(data, (success) => {
      updateRerender(!rerender);
    });
  };
  const columns = [
    {
      title: "اسم المدير",
      key: "fullName",
      dataIndex: "fullName",
    },
    {
      title: "الهاتف",
      key: "phone",
      dataIndex: "phone",
    },
    {
      title: "Actions",
      key: "isActive",
      dataIndex: "isActive",
      render: (isActive, record) => {
        return (
          <div
            className={`${isActive ? "deactivateRecord" : "activateRecord"}`}
            onClick={() => {
              changeAdminStatusFunction(record._id, !isActive);
            }}
          >
            {`${isActive ? "Deactivate" : "Activate"}`}
          </div>
        );
      },
    },
  ];
  const handleAddAdmin = (e) => {
    e.preventDefault();
    updateLoading(true);
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(phone)) {
      let data = {
        fullName,
        password,
        phone,
      };

      addAdmins(
        data,
        (success) => {
          updateLoading(false);
          if (success.isSuccess) {
            toastSuccess(success.message);
            updateMode(null);
            updateFullName("");
            updatePassword("");
            updatePhone("");
            updateRerender((prev) => !prev);
            // getAllAdmins((success) => {
            //   updateDataSource(success.data);
            // });
          }
        },
        (fail) => {
          updateLoading(false);
        }
      );
    } else {
      updateLoading(false);
      alert("من فضلك ادخل رقم تليفون صحيح");
    }
  };
  return (
    <div className="d-flex">
      <SideBar parent={"admins"} />
      <div className="flex-1 section">
        <NavBar
          getSearchedValue={(value) => {
            updateSearchValue(value);
          }}
          disableSearchBar={mode === "add"}
        />
        <div className="mx-4">
          <div className="d-flex justify-content-between">
            <h3 className="f-36 fw-700">
              المديرون{" "}
              {mode === "add" ? "- Add" : mode === "edit" ? "- Edit" : ""}
            </h3>
            {!mode && (
              <Button
                className="secondary_btn"
                onClick={() => {
                  updateMode("add");
                }}
              >
                اضافة مدير
              </Button>
            )}
          </div>
        </div>
        {mode === "add" ? (
          <form onSubmit={handleAddAdmin} className="homeSection  p-2 mx-4">
            <div className="d-flex align-items-center">
              <div className="f-18 mx-2 fw-700 w-20">Full Name</div>
              <Input
                type="text"
                placeholder="اسم المدير"
                className="homeSection-input"
                value={fullName}
                onChange={(e) => {
                  updateFullName(e.target.value);
                }}
              />
            </div>
            <div className="d-flex align-items-center">
              <div className="f-18 mx-2 fw-700 w-20">Password</div>

              <Input.Password
                name="password"
                onChange={(e) => {
                  updatePassword(e.target.value);
                }}
                value={password}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                placeholder="كلمة المرور"
                prefix={<img src={lock} alt="lock" />}
                className="homeSection-input"
              />
            </div>

            <div className="d-flex align-items-center">
              <div className="f-18 mx-2 fw-700 w-20">Phone</div>
              <Input
                type="phone"
                placeholder="رقم الهاتف"
                className="homeSection-input"
                value={phone}
                prefix={<img src={call} alt="call" />}
                onChange={(e) => {
                  updatePhone(e.target.value);
                }}
              />
            </div>
            <div className="d-flex justify-content-end my-2">
              <Button
                className="primary_btn"
                loading={loading}
                type="primary"
                htmlType="submit"
                disabled={!phone || !password || !fullName}
              >
                تأكيد
              </Button>
            </div>
          </form>
        ) : (
          <Grid
            loading={tableLoading}
            mainTitle={""}
            subTitle={"كل المديرون"}
            columns={columns}
            dataSource={dataSource}
            emptyTableText={{
              emptyText: "لا يوجد مديرون",
            }}
          />
        )}
      </div>
    </div>
  );
}

export default Admins;
