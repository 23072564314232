import React from "react";
import { Link } from "react-router-dom";
import packages from "../../../../Assets/Icons/packages.svg";
import users from "../../../../Assets/Icons/users.svg";
// star from "../../../../Assets/Icons/star.svg";
import admins from "../../../../Assets/Icons/admins.svg";
// import homeSection from "../../../../Assets/Icons/homeSection.svg";
// import packs from "../../../../Assets/Icons/packs.svg";
// import chicapa from "../../../../Assets/Icons/chicapa.svg";
// import transactions from "../../../../Assets/Icons/transactions.svg";
// import settings from "../../../../Assets/Icons/settings.svg";

function DesktopSideBar({ parent }) {
  return (
    <div className="sideBar">
      <div className="ant-drawer-body">
        <h3 className="f-36 mainTitle text-center my-4 fw-800">مقرأة</h3>
        <Link
          to="/users"
          className={`${parent === "users" && "activeTab"} d-flex  tab`}
        >
          <img src={users} alt="users" />
          <label className="mx-4 f-17 cursor-pointer">الطلاب</label>
        </Link>
        {/* <Link
          to="/present-students"
          className={`${
            parent === "present-students" && "activeTab"
          } d-flex  tab`}
        >
          <img src={users} alt="present-students" />
          <label className="mx-4 f-17 cursor-pointer">الطلاب الحاضرين</label>
        </Link> */}
        <Link
          to="/admins"
          className={`${parent === "admins" && "activeTab"} d-flex  tab`}
        >
          <img src={admins} alt="admins" />
          <label className="mx-4 f-17 cursor-pointer">المديرون</label>
        </Link>
        <Link
          to="/sheikhs"
          className={`${parent === "sheikhs" && "activeTab"} d-flex  tab`}
        >
          <img src={packages} alt="packages" />
          <label className="mx-4 f-17 cursor-pointer">المعلمون</label>
        </Link>
        <Link
          to="/sheikhList"
          className={`${parent === "sheikhlist" && "activeTab"} d-flex  tab`}
        >
          <img src={packages} alt="packages" />
          <label className="mx-4 f-17 cursor-pointer">كل المعلمون</label>
        </Link>
        <Link
          to="/endedlectures"
          className={`${parent === "endedlectures" && "activeTab"} d-flex  tab`}
        >
          <img src={packages} alt="packages" />
          <label className="mx-4 f-17 cursor-pointer">المحاضرات</label>
        </Link>
        <Link
          to="/endedLectureWithAttenders"
          className={`${
            parent === "lectureWithAttenders" && "activeTab"
          } d-flex  tab`}
        >
          <img src={packages} alt="packages" />
          <label className="mx-4 f-17 cursor-pointer">الحضور</label>
        </Link>
        <Link
          to="/groups"
          className={`${parent === "groups" && "activeTab"} d-flex  tab`}
        >
          <img src={packages} alt="packages" />
          <label className="mx-4 f-17 cursor-pointer">المجموعات</label>
        </Link>
        <Link
          to="/number-of-attendence"
          className={`${
            parent === "numberOfAttendence" && "activeTab"
          } d-flex  tab`}
        >
          <img src={packages} alt="numberOfAttendence" />
          <label className="mx-4 f-17 cursor-pointer">الطلاب الحضور</label>
        </Link>

        {/* <Link
					to="/home"
					className={`${parent === "home" && "activeTab"} d-flex  tab`}
				>
					<img src={homeSection} alt="homeSection" />
					<label className="mx-4 f-17 cursor-pointer">Home Sections</label>
				</Link>
				<Link
					to="/chicapa"
					className={`${parent === "chicapa" && "activeTab"} d-flex  tab`}
				>
					<img src={chicapa} alt="chicapa" />
					<label className="mx-4 f-17 cursor-pointer">Chicapa</label>
				</Link>
				<Link
					to="/packs"
					className={`${parent === "packs" && "activeTab"} d-flex  tab`}
				>
					<img src={packs} alt="packs" />
					<label className="mx-4 f-17">Packs</label>
				</Link>
				<Link
					to="/promoPacks"
					className={`${parent === "promoPacks" && "activeTab"} d-flex  tab`}
				>
					<img src={star} alt="promoPacks" />
					<label className="mx-4 f-17 cursor-pointer">Promo Packs</label>
				</Link>
				<Link
					to="/transactions"
					className={`${parent === "transactions" && "activeTab"} d-flex  tab`}
				>
					<img src={transactions} alt="transactions" />
					<label className="mx-4 f-17 cursor-pointer">Transactions</label>
				</Link>
				<Link
					to="/settings"
					className={`${parent === "settings" && "activeTab"} d-flex  tab`}
				>
					<img src={settings} alt="settings" />
					<label className="mx-4 f-17 cursor-pointer">Settings</label>
				</Link> */}
      </div>
    </div>
  );
}

export default DesktopSideBar;
