import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SideBar from "../Common/SideBar/SideBar";
import { allNotInGroupUsers, addGroup, getAllActiveSheikhs } from "../../Network/Network";
import { Button, Form } from "react-bootstrap";
import { Input } from "antd";
import { toastSuccess } from "../../Helpers/toasters";
import Select from "react-select";

export default function AddGroup() {
    const [dataSource, updateDataSource] = useState([]);
    const [allSheikDataSource, updateAllDataSource] = useState([]);
    // eslint-disable-next-line
    const [rerender, updateRerender] = useState(false);
    const [groupName, updateGroupName] = useState("");
    const [studentId, updateStudentId] = useState("");
    const [sheikhId, updateSheikhId] = useState("");
    const [loading, updateLoading] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState();
    const Navigate=useNavigate()
    useEffect(() => {
        allNotInGroupUsers((success) => {
            updateDataSource(success.data);
        });
        getAllActiveSheikhs((success) => {
            updateAllDataSource(success.data);
        })
        // eslint-disable-next-line
    }, [rerender]);
    const handleAddGroup = (e) => {
        e.preventDefault();
        updateLoading(true);
        let data = {
            groupName,
            sheikhId,
            students: selectedOptions&&selectedOptions.length>0?selectedOptions.map(a => a.value):[]
        };
        addGroup(
            data,
            (success) => {
                updateLoading(false);
                if (success.isSuccess) {
                    toastSuccess(success.message);
                    updateGroupName("");
                    updateSheikhId("");
                    Navigate("/groups")
                }
            },
            (fail) => {
                updateLoading(false);
            }
        );
    };


    const dropDown = (datasource) => {
        return (
            <Form.Control
                style={{ width: "50%" }}
                as="select"
                onChange={(event) => {
                    updateStudentId(event.target.value)
                }
                }
            >
                <option selected disabled hidden value="">
                    اختر الطالب
                </option>
                {datasource.length > 0 &&
                    datasource.map((item) => {
                        return (
                            <option value={item._id}>
                                {item.fullName}
                            </option>
                        );
                    })}
            </Form.Control>
        )
    }
    function handleSelect(data) {
        setSelectedOptions(data);
  }
    const studentDropDown=()=>{
        return (
            <div className="dropDownSelect">
              <h2>اختر الطالب</h2>
              <div className="dropdown-container">
                <Select
                  options={dataSource}
                  placeholder="اختر الطالب"
                  value={selectedOptions}
                  onChange={handleSelect}
                  isSearchable={true}
                  isMulti
                />
              </div>
            </div>
          );
      }
    return (
        <div className="d-flex">
            <SideBar parent={"users"} />
            <div className="flex-1 section">
                <form onSubmit={handleAddGroup} className="homeSection  p-2 mx-4">
                    <div className="d-flex align-items-center">
                        {/* <div className="f-18 mx-2 fw-700 w-20">اسم المجموعة </div> */}
                        <h2>اسم المجموعة</h2>

                        <Input
                            type="text"
                            placeholder="اسم المجموعة"
                            className="homeSection-input"
                            required
                            value={groupName}
                            onChange={(e) => {
                                updateGroupName(e.target.value);
                            }}
                        />
                    </div>
                    <h2>اختر الشيخ</h2>
                    <Form.Control
                        //style={{width:"50%"}}
                        as="select"
                        required
                        onChange={(event) => {
                            updateSheikhId(event.target.value)
                        }
                        }
                    >
                        <option selected disabled hidden value="">
                            اختر الشيخ
                        </option>
                        {allSheikDataSource.length > 0 &&
                            allSheikDataSource.map((item) => {
                                return (
                                    <option value={item._id}>
                                        {item.fullName}
                                    </option>
                                );
                            })}
                    </Form.Control>

                    <div >
                        {studentDropDown()}
                        {/* <Form.Control
                            //style={{width:"50%"}}
                            as="select"
                            onChange={(event) => {
                                updateStudentId(event.target.value)
                            }
                            }
                        >
                            <option selected disabled hidden value="">
                                اختر الطالب
                            </option>
                            {dataSource.length > 0 &&
                                dataSource.map((item) => {
                                    return (
                                        <option value={item._id}>
                                            {item.fullName}
                                        </option>
                                    );
                                })}
                        </Form.Control>
                        <Button
                            variant="primary"
                            style={{ margin: "2px" }}
                            onClick={(event) => { fillStudentArray(studentId) }}
                        >
                            اضافة الى المجموعة
                        </Button> */}
                    </div>
                    <div className="d-flex justify-content-end my-2">
                        <Button
                            className="primary_btn"
                            loading={loading}
                            type="primary"
                            htmlType="submit"
                        //	disabled={!phone || !password || !fullName}
                        >
                            تأكيد
                        </Button>
                    </div>
                </form>
                <br />
            </div>

        </div>
    );
}
