import { Button } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { exportToExcel, searchFunction } from "../../Helpers/helpers";
import { getPresentStudents } from "../../Network/Network";
import Grid from "../Common/Grid/Grid";
import NavBar from "../Common/NavBar/NavBar";
import SideBar from "../Common/SideBar/SideBar";
// import { XLSX } from "xlsx";
import XLSX from "xlsx/dist/xlsx.full.min.js";

function PresentStudents() {
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [renderMe, setRenderMe] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [allDataSource, setAllDataSource] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const { lectureId } = location.state;

  useEffect(() => {
    // setTableLoading(true);

    let searchColumns = ["firstName", "lastName", "phone"];
    let searchResult = searchFunction(
      allDataSource,
      searchValue,
      searchColumns,
      "userId"
    );
    setDataSource(searchResult);
    // setTableLoading(false);
    //eslint-disable-next-line
  }, [searchValue]);
  useEffect(() => {
    if (lectureId) {
      if (searchValue) {
        getAllPresentStudents(true);
      } else {
        getAllPresentStudents();
      }
    } else {
      navigate("/endedLectureWithAttenders");
    } //eslint-disable-next-line
  }, [lectureId, renderMe]);

  const getAllPresentStudents = () => {
    getPresentStudents(
      { lectureId },
      (success) => {
        if (success?.isSuccess) {
          setDataSource(success.data);
          setAllDataSource(success.data);
          setTableLoading(false);
        }
      },
      (fail) => {}
    );
  };
  const columns = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      render: (_, r) => {
        console.log("r", r);
        return <span>{`${r?.userId?.firstName} ${r?.userId?.lastName}`}</span>;
      },
    },
    {
      key: "phone",
      title: "Mobile",
      dataIndex: "phone",
      render: (_, r) => <span>{r?.userId?.phone}</span>,
    },
  ];

  const handleExportToExcel = () => {
    let filteredData = [];
    dataSource.map((user) =>
      filteredData.push({
        Name: `${user?.userId?.firstName} ${user?.userId?.lastName}`,
        Phone: user?.userId?.phone,
      })
    );
    exportToExcel(filteredData);
  };

  return (
    <div className="d-flex">
      <SideBar parent={"present-students"} />
      <div className="flex-1 section">
        <NavBar
          getSearchedValue={(value) => {
            setSearchValue(value);
          }}
        />
        <Grid
          mainTitle={"الطلاب الحاضرون"}
          subTitle={"كل الطلاب الحاضرون"}
          columns={columns}
          dataSource={dataSource}
          loading={tableLoading}
          rowKey={"_id"}
          exportMe={true}
          options={true}
          exportToExcel={handleExportToExcel}
          emptyTableText={{
            emptyText: "لا يوجد طلاب",
          }}
        />
      </div>
    </div>
  );
}

export default PresentStudents;
