import { Col, Row, Spin } from "antd";
import { useState } from "react";
import Countdown from "antd/lib/statistic/Countdown";
import OtpInput from "react-otp-input";
import { useNavigate, useLocation } from "react-router-dom";
import {
	ConfirmLoginVerificationCode,
	sendVerificationCode,
} from "../../../Network/Network";

function OTP() {
	const [loading, updateLoading] = useState(false);
	const [code, setCode] = useState("");
	const [showTimer, setShowTimer] = useState();
	const navigate = useNavigate();
	const location = useLocation();

	const handleResendCode = () => {
		updateLoading(true);
		setShowTimer(true);
		let email =
			location.search.split("email=").length > 0 &&
			location.search.split("email=")[1];
		if (email) {
			sendVerificationCode(
				{ email },
				(success) => {
					if (success.isSuccess) {
						updateLoading(false);
					}
				},
				(fail) => {
					updateLoading(false);
					// navigate('/')
				}
			);
		}
	};
	function onfinishTimer() {
		setShowTimer(false);
	}
	const handleConfirmCode = (e) => {
		e.preventDefault();
		let email =
			location.search.split("email=").length > 0 &&
			location.search.split("email=")[1];
		if (email) {
			let data = {
				email,
				code,
			};
			ConfirmLoginVerificationCode(
				data,
				(success) => {
					if (success.success) {
						navigate(`/changePassword${location.search}`);
					}
				},
				(fail) => {
					console.log(fail);
				}
			);
		}
	};
	return (
		<div className="otp d-flex flex-column flex-1 align-items-center justify-content-center">
			<Row>
				<Col xs={24} md={12} className="solidArea"></Col>
				<Col
					xs={24}
					md={12}
					className="d-flex flex-column flex-1 align-items-center justify-content-center form"
				>
					<form className="width-80 text-center" onSubmit={handleConfirmCode}>
						<h3 className="mainTitle mb-2 fw-800">Confirmation Code</h3>
						<div className="f-25 text-center enterEmail">
							Enter the OTP code that you received on your mailbox
						</div>
						{showTimer && (
							<Countdown
								className="text-center auth__timer"
								value={Date.now() + 1000 * 6 * 10}
								format="mm:ss"
								onFinish={onfinishTimer}
							/>
						)}
						<div className="d-flex justify-content-center my-4">
							<OtpInput
								value={code}
								onChange={setCode}
								isInputNum
								numInputs={6}
								shouldAutoFocus
								className="otpcode-box"
							/>
						</div>
						<button
							disabled={loading || code.length !== 6}
							className="primary_btn my-2"
						>
							{loading ? <Spin /> : "Confirm"}
						</button>
						<div className="mainColorDarker">
							Didn’t receive a code?
							<span
								className="mainColor underline cursor-pointer"
								onClick={handleResendCode}
							>
								Resend it
							</span>
						</div>
					</form>
				</Col>
			</Row>
		</div>
	);
}

export default OTP;
