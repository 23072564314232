import axios from "axios";
import { SIGNOUT } from "../Store/authorization";
import { useDispatch } from "react-redux";

import { toastError, toastSuccess } from "../Helpers/toasters";

export const baseUrl =
   //"http://ec2-54-194-113-34.eu-west-1.compute.amazonaws.com:1140/";
 "https://api.maqraa.info/";

export let jwtToken = localStorage.getItem("token");

export function getJWTToken(newToken) {
  jwtToken = newToken;
  localStorage.setItem("token", newToken);
}
export function getResource(path, onSuccess, onFail, reqAuth = true) {
  let requestData = {
    method: "get",
    url: baseUrl + path,
    headers: {},
  };

  if (reqAuth) {
    requestData.headers = {
      Authorization: "Bearer " + jwtToken,
    };
  }

  axios(requestData)
    .then(
      (res) => {
        onSuccess(res.data);
      },
      (fail) => {
        if (fail?.response?.status === 401 || fail?.response?.status === 403) {
          const dispatch = useDispatch();
          dispatch(SIGNOUT());
          localStorage.removeItem("token");
        } else {
          onFail(fail.response);
          toastError(fail.response.message);
        }
      }
    )
    .catch((err) => {
      console.log("erroro is ", err);
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        const dispatch = useDispatch();
        dispatch(SIGNOUT());
        localStorage.removeItem("token");
      } else {
        onFail(err);
      }
    });
}

export function postResource(
  path,
  data,
  onSuccess,
  onFail,
  reqAuth = true,
  multiPart = false
) {
  let requestData = {
    method: "post",
    url: baseUrl + path,
    headers: {},
    data,
  };

  if (jwtToken && reqAuth) {
    requestData.headers = {
      ...requestData.headers,
      Authorization: "Bearer " + jwtToken,
    };
  }

  if (multiPart) {
    requestData.headers = {
      ...requestData.headers,
      "content-type": "multipart/form-data",
    };
  }
  //console.log("requestData",requestData)

  //console.log("path",path)

  axios(requestData)
    .then(
      (res) => {
        if (!res.data.isSuccess) {
          toastError(res.data.message);
        }
        onSuccess(res.data);
      },
      (fail) => {
        console.log("fail.response", fail.response);
        onFail(fail.response);
        toastError(fail.response.data?.error);
      }
    )
    .catch((err) => {
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        window.location = "/";
      } else {
        onFail(err);
      }
    });
}

export function deleteResource(path, data, onSuccess, onFail, reqAuth = true) {
  let requestData = {
    method: "delete",
    url: baseUrl + path,
    headers: {},
    data,
  };

  if (jwtToken && reqAuth) {
    requestData.headers = {
      Authorization: "Bearer " + jwtToken,
    };
  }

  axios(requestData)
    .then(
      (res) => {
        if (!res.data.isSuccess) {
          toastError(res.data.message);
        }
        onSuccess(res.data);
      },
      (fail) => {
        onFail(fail.response);
        toastError(fail.response.message);
      }
    )
    .catch((err) => {
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        window.location = "/";
      } else {
        onFail(err);
      }
    });
}

export function patchResource(
  path,
  data,
  onSuccess,
  onFail,
  reqAuth = true,
  multipart = false
) {
  let requestData = {
    method: "patch",
    url: baseUrl + path,
    headers: {},
    data,
  };

  if (jwtToken && reqAuth) {
    requestData.headers = {
      ...requestData.headers,
      Authorization: "Bearer " + jwtToken,
    };
  }

  if (multipart) {
    requestData.headers = {
      ...requestData.headers,
      "content-type": "multipart/form-data",
    };
  }

  axios(requestData)
    .then(
      (res) => {
        if (!res.data.isSuccess) {
          toastError(res.data.message);
        }
        onSuccess(res.data);
      },
      (fail) => {
        onFail(fail.response);
        toastError(fail.response.message);
      }
    )
    .catch((err) => {
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        window.location = "/";
      } else {
        onFail(err);
      }
    });
}
export function putResource(
  path,
  data,
  onSuccess,
  onFail,
  reqAuth = true,
  multipart = false
) {
  let requestData = {
    method: "put",
    url: baseUrl + path,
    headers: {},
    data,
  };

  if (jwtToken && reqAuth) {
    requestData.headers = {
      ...requestData.headers,
      Authorization: "Bearer " + jwtToken,
    };
  }

  if (multipart) {
    requestData.headers = {
      ...requestData.headers,
      "content-type": "multipart/form-data",
    };
  }

  axios(requestData)
    .then(
      (res) => {
        if (!res.data.isSuccess) {
          toastError(res.data.message);
        } else {
          toastSuccess(res.data.message);
        }
        onSuccess(res.data);
      },
      (fail) => {
        onFail(fail.response);
        toastError(fail.response.message);
      }
    )
    .catch((err) => {
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        window.location = "/";
      } else {
        onFail(err);
      }
    });
}
