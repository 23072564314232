import { useState, useEffect, useRef } from "react";
import { searchFunction } from "../../Helpers/helpers";
import { SearchOutlined, SettingTwoTone } from "@ant-design/icons";
import moment from "moment";
import NavBar from "../Common/NavBar/NavBar";
import SideBar from "../Common/SideBar/SideBar";
import {
  ChangeSheikhStatus,
  getAllSheikhsforApprove,
} from "../../Network/Network";
import { Button, Input, Space, Table } from "antd";
import Grid from "../Common/Grid/Grid";
function SheikhList() {
  // eslint-disable-next-line
  const [mode, updateMode] = useState("");
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);

  const [dataSource, updateDataSource] = useState([]);
  const [allDataSource, updateAllDataSource] = useState([]);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [rerender, updateRerender] = useState(false);
  const [searchValue, updateSearchValue] = useState("");
  const [searchText, updateSearchText] = useState("");
  const [searchedColumn, updateSearchedColumn] = useState("");
  let searchInput = useRef(null); // ref => { current: null }

  useEffect(() => {
    getShikhs();
    // eslint-disable-next-line
  }, [rerender]);

  useEffect(() => {
    // setTableLoading(true);
    let searchColumns = ["firstName", "lastName", "phone", "isApproved"];
    let searchResult = searchFunction(
      allDataSource,
      searchValue,
      searchColumns
    );
    updateDataSource(searchResult);
    setTableLoading(false);
    // eslint-disable-next-line
  }, [searchValue]);

  const getShikhs = (search = false) => {
    getAllSheikhsforApprove(
      (success) => {
        if (success?.isSuccess) {
          updateDataSource(success.data);
          updateAllDataSource(success.data);
          setTableLoading(false);
        }
      },
      (fail) => {}
    );
  };

  const changeSheikhStatusFunction = (sheikhId, isAproved) => {
    let data = {
      sheikhId,
      isAproved,
    };
    //console.log("data",data)
    ChangeSheikhStatus(data, (success) => {
      updateRerender(!rerender);
    });
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    updateSearchText(selectedKeys[0]);
    updateSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    updateSearchText("");
    updateSearchedColumn("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              updateSearchText(selectedKeys[0]);
              updateSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex
        ? // <Highlighter
          // 	highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          // 	searchWords={[searchText]}
          // 	autoEscape
          // 	textToHighlight={text ? text.toString() : ""}
          // />
          text
        : text,
  });

  const columns = [
    {
      title: "الاسم",
      key: "firstName",
      dataIndex: "firstName",
      ...getColumnSearchProps("firstName"),
      sortDirections: ["descend"],
    },
    {
      title: "اللقب",
      key: "lastName",
      dataIndex: "lastName",
      ...getColumnSearchProps("lastName"),

      onFilter: (value, record) => record.lastName.indexOf(value) === 0,
      sortDirections: ["descend"],
    },
    {
      title: "الهاتف",
      key: "phone",
      dataIndex: "phone",
      ...getColumnSearchProps("phone"),
      onFilter: (value, record) => record.phone.indexOf(value) === 0,
      sortDirections: ["descend"],
    },
    {
      title: "Actions",
      key: "isApproved",
      dataIndex: "isApproved",
      render: (isApproved, record) => {
        return (
          <div
            className={`${isApproved ? "deactivateRecord" : "activateRecord"}`}
            onClick={() => {
              changeSheikhStatusFunction(record._id, !isApproved);
            }}
          >
            {`${isApproved ? "DisApprove" : "Approve"}`}
          </div>
        );
      },
    },
  ];

  return (
    <div className="d-flex">
      <SideBar parent={"sheikhlist"} />
      <div className="flex-1 section">
        <NavBar
          getSearchedValue={(value) => {
            updateSearchValue(value);
          }}
          disableSearchBar={mode === "add"}
        />
        <div className="d-flex justify-content-end flex-1 my-2"></div>
        {
          <Grid
            loading={tableLoading}
            mainTitle={"كل المعلمون"}
            subTitle={"كل المعلمون"}
            columns={columns}
            dataSource={dataSource}
            emptyTableText={{
              emptyText: "لا يوجد معلمون",
            }}
          />
        }
      </div>
    </div>
  );
}

export default SheikhList;
