import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import NavBar from "../Common/NavBar/NavBar";
import SideBar from "../Common/SideBar/SideBar";
import Grid from "../Common/Grid/Grid";
import { getAllGroupUsers, getUserSurvey } from "../../Network/Network";
import { searchFunction } from "../../Helpers/helpers";
function GroupUsersPage() {
  const [tableLoading, setTableLoading] = useState(true);

  const [dataSource, updateDataSource] = useState([]);
  const [allDataSource, updateAllDataSource] = useState([]);
  // eslint-disable-next-line
  const [rerender, updateRerender] = useState(false);
  const [searchValue, updateSearchValue] = useState("");
  const Navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // setTableLoading(true);

    const searchColumn = ["firstName", "lastName", "phone"];
    const searchedDataSource = searchFunction(
      allDataSource,
      searchValue,
      searchColumn,
      "userId"
    );
    updateDataSource(searchedDataSource);
    // setTableLoading(false);

    // eslint-disable-next-line
  }, [searchValue]);

  useEffect(() => {
    getGroups();
    // eslint-disable-next-line
  }, [, rerender]);

  const getGroups = () => {
    let data = { groupId: location?.state?.groupId };
    getAllGroupUsers(data, (success) => {
      if (success?.isSuccess) {
        updateDataSource(success.data);
        updateAllDataSource(success.data);
        setTableLoading(false);
      }
    });
  };

  const getUserSurveyData = (userId) => {
    let data = {
      userId,
    };
    getUserSurvey(data, (success) => {
      Navigate("/survey", {
        state: {
          data: success.data,
          userId,
        },
      });
    });
  };
  const columns = [
    {
      title: "الاسم",
      key: "_id",
      dataIndex: "name",
      render: (_id, record) => {
        return (
          <div className="fw-800">
            {record?.userId?.firstName + " " + record?.userId?.lastName}
          </div>
        );
      },
    },
    {
      title: "الموبايل",
      key: "_id",
      dataIndex: "phone",
      render: (_id, record) => {
        return <div className="fw-800">{record.userId.phone}</div>;
      },
    },
    // {
    // 	title: "Email",
    // 	key: "email",
    // 	dataIndex: "email",
    // },
    // {
    // 	title: "رقم الهاتف",
    // 	key: "mobile",
    // 	dataIndex: "mobile",
    // },
    // {
    // 	title: "المجموعة",
    // 	key: "groupName",
    // 	dataIndex: "groupName",
    // },
    {
      title: "الاستبيان",
      key: "survey",
      dataIndex: "survey",
      render: (_data, record) => {
        return (
          <Button
            variant="primary"
            style={{ margin: "2px" }}
            onClick={(event) => {
              getUserSurveyData(record.userId._id);
            }}
          >
            الاستبيان
          </Button>
        );
      },
    },
  ];
  return (
    <div className="d-flex">
      <SideBar parent={"users"} />
      <div className="flex-1 section">
        <NavBar
          getSearchedValue={(value) => {
            updateSearchValue(value);
          }}
        />
        <Grid
          loading={tableLoading}
          mainTitle={"الطلاب"}
          subTitle={"كل الطلاب"}
          columns={columns}
          dataSource={dataSource}
          emptyTableText={{
            emptyText: "لا يوجد طلاب لهذه المجموعة",
          }}
        />
      </div>
    </div>
  );
}

export default GroupUsersPage;
