import React, { useState } from "react";

const FloatInput = props => {
    const [focus, setFocus] = useState(false);
    const { children, label, value } = props;

    const labelClass = focus || value ? "label-float" : "label";

    return (
        <div
            className="float-label"
            onBlur={() => setFocus(false)}
            onFocus={() => setFocus(true)}
        >
            {children}
            <label className={labelClass}>{label}</label>
        </div>
    );
};

export default FloatInput;