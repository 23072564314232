import { createSlice } from "@reduxjs/toolkit";

export const authorizationSlice = createSlice({
	name: "authorization",
	initialState: {
		authorization: {},
		token: null,
	},
	reducers: {
		SIGNIN: (state, action) => {
			state.authorization = action.payload;
		},
		CHANGAUTHDATA: (state, action) => {
			state.authorization = { ...state.authorization, ...action.payload };
		},
		ADDFIREBASETOKEN: (state, action) => {
			state.token = action.payload;
		},
		SIGNOUT: (state, action) => {
			state.authorization = {};
		},
		CHANGEAUTHIMAGE: (state, action) => {
			state.authorization.imagePath = action.payload;
		},
	},
});

const { reducer, actions } = authorizationSlice;
export const {
	SIGNIN,
	SIGNOUT,
	CHANGAUTHDATA,
	CHANGEAUTHIMAGE,
	ADDFIREBASETOKEN,
} = actions;
export default reducer;
