import { useState, useEffect, useRef } from "react";
import { exportToExcel, searchFunction } from "../../Helpers/helpers";
import { SearchOutlined } from "@ant-design/icons";
import NavBar from "../Common/NavBar/NavBar";
import SideBar from "../Common/SideBar/SideBar";
import {
  getActivelectures,
  getUsersWithTheirLectures,
} from "../../Network/Network";
import { Button, Input, Space } from "antd";
import { useNavigate } from "react-router-dom";
import Grid from "../Common/Grid/Grid";
import moment from "moment";
import { toastError } from "../../Helpers/toasters";

function EndedLecturesWithAttenders() {
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [dataSource, updateDataSource] = useState([]);
  const [allDataSource, updateAllDataSource] = useState([]);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [searchValue, updateSearchValue] = useState("");
  let [dateFrom, setDateFrom] = useState(null);
  let [dateTo, setDateTo] = useState(null);
  const [searchedColumn, updateSearchedColumn] = useState("");
  const navigate = useNavigate();
  let searchInput = useRef(null);

  useEffect(() => {
    getLecturesData();
    // eslint-disable-next-line
  }, [dateFrom, dateTo]);

  useEffect(() => {
    // setTableLoading(true)
    let searchColumns = [
      "userName",
      "mobile",
      "createdAt",
      "sheikhName",
      "description",
    ];
    let searchResult = searchFunction(
      allDataSource,
      searchValue,
      searchColumns
    );
    updateDataSource(searchResult);
    setFilteredDataSource(searchResult);
    // setTableLoading(false);
    // eslint-disable-next-line
  }, [searchValue]);

  const getLecturesData = () => {
    getUsersWithTheirLectures(
      (success) => {
        if (success?.isSuccess) {
          updateDataSource(success.data);
          updateAllDataSource(success.data);
          setFilteredDataSource(success.data);
          setTableLoading(false);
        } else {
          toastError();
        }
      },
      (fail) => {
        toastError();
      },
      { from: dateFrom, to: dateTo }
    );
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    updateSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    updateSearchedColumn("");
    if (dataSource?.length) {
      setFilteredDataSource(dataSource);
    }
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              // updateSearchText(selectedKeys[0]);
              updateSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex
        ? // <Highlighter
          // 	highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          // 	searchWords={[searchText]}
          // 	autoEscape
          // 	textToHighlight={text ? text.toString() : ""}
          // />
          text
        : text,
  });
  function changeDate(date) {
    if (date) {
      setDateFrom(date[0]);
      setDateTo(date[1]);
    } else {
      setDateFrom(null);
      setDateTo(null);
    }
  }
  const columns = [
    {
      title: "اسم الطالب",
      key: "userName",
      dataIndex: "userName",
      ...getColumnSearchProps("userName"),
      onFilter: (value, record) => record.userName.indexOf(value) === 0,
    },
    {
      title: " رقم الموبايل",
      key: "mobile",
      dataIndex: "mobile",
      sorter: (a, b) => a.mobile - b.mobile,
      sortDirections: ["descend"],
    },
    {
      title: "تاريخ المحاضرة",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (_, record) => {
        return (
          <span dir="ltr">
            {moment(record?.createdAt).format("YYYY-MM-DD HH:mm:ss")}
          </span>
        );
      },
      sortDirections: ["descend"],
    },
    {
      title: "وصف المحاضرة",
      key: "description",
      dataIndex: "description",
      ...getColumnSearchProps("description"),
      onFilter: (value, record) => record?.description.indexOf(value) === 0,
    },
    {
      title: "المعلم",
      key: "sheikhName",
      dataIndex: "sheikhName",
      ...getColumnSearchProps("sheikhName"),
      onFilter: (value, record) => record?.sheikhName.indexOf(value) === 0,
    },
  ];

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setFilteredDataSource(extra?.currentDataSource);
    updateDataSource(extra?.currentDataSource);
  };

  const handleExportToExcel = () => {
    let filteredData = [];
    filteredDataSource.map((lec) =>
      filteredData.push({
        "Student Name ": `${lec?.userName}`,
        "Phone number ": `${lec?.mobile}`,
        "Lecture date": `${lec?.createdAt.substring(0, 10)} `,
        Sheikh: `${lec?.sheikhName}`,
      })
    );
    const data = filteredData;
    exportToExcel(data);
  };

  return (
    <div className="d-flex">
      <SideBar parent={"numberOfAttendence"} />
      <div className="flex-1 section">
        <NavBar
          getSearchedValue={(value) => {
            updateSearchValue(value);
          }}
        />
        <Grid
          mainTitle={"الطلاب الحضور"}
          columns={columns}
          dataSource={dataSource}
          loading={tableLoading}
          rowKey={"_id"}
          exportMe={true}
          options={true}
          searchFromTo={true}
          exportToExcel={handleExportToExcel}
          changeDate={changeDate}
          onChange={handleTableChange}
          emptyTableText={{
            emptyText: "لا توجد محاضرات",
          }}
        />
      </div>
    </div>
  );
}

export default EndedLecturesWithAttenders;
