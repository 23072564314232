import {
  postResource,
  getResource,
  putResource,
  //deleteResource,
} from "./Services";

export const handleSignin = (data, onSuccess, onFail) => {
  const path = "api/Admin/Login";
  postResource(path, data, onSuccess, onFail, false);
};

export const sendVerificationCode = (data, onSuccess, onFail) => {
  const { email } = data;
  const path = `api/Admin/SendMail?email=${email}`;
  postResource(path, {}, onSuccess, onFail, false);
};
export const ConfirmLoginVerificationCode = (data, onSuccess, onFail) => {
  const path = "api/Admin/ConfirmCode";
  postResource(path, data, onSuccess, onFail, false);
};

export const resetPassword = (data, onSuccess, onFail) => {
  const path = "api/Admin/AddNewPassword";
  postResource(path, data, onSuccess, onFail, false);
};
// export const getAllUsers = (onSuccess, onFail) => {
//   const path = "api/users/allUsers";
//   getResource(path, onSuccess, onFail);
// };
export const getAllUsers = (onSuccess, onFail, data) => {
  const path = "api/users/allUsers";
  postResource(path, data, onSuccess, onFail);
};
export const getPresentStudents = ({ lectureId }, onSuccess, onFail) => {
  const path = `api/users/GetLectureStudents?lectureId=${lectureId}`;
  getResource(path, onSuccess, onFail);
};

export const getAllGroupUsers = (data, onSuccess, onFail) => {
  const { groupId } = data;
  const path = `api/users/allGroupUsers?groupId=${groupId}`;
  getResource(path, onSuccess, onFail);
};
export const ChangeUserStatus = (data, onSuccess, onFail) => {
  const path = "api/User/ChangeUserStatus";
  putResource(path, data, onSuccess, onFail);
};
export const getAllAdmins = (onSuccess, onFail) => {
  const path = "api/admin/allAdmins";
  getResource(path, onSuccess, onFail);
};

export const addAdmins = (data, onSuccess, onFail) => {
  const path = "api/Admin/addAdmin";
  postResource(path, data, onSuccess, onFail);
};
export const ChangeAdminStatus = (data, onSuccess, onFail) => {
  const { adminId, isActive } = data;
  const path = `api/admin/changeAdminStatus?adminId=${adminId}&isActive=${isActive}`;
  putResource(path, data, onSuccess, onFail);
};
export const getAllSheikhs = (onSuccess, onFail, data) => {
  const path = "api/sheikh/allSheikhsForAdmins";
  postResource(path, data, onSuccess, onFail);
};
export const getAllSheikhsforApprove = (onSuccess, onFail) => {
  const path = "api/sheikh/allSheikhsForAdminsForApprove";
  getResource(path, onSuccess, onFail);
};
export const ChangeSheikhStatus = (data, onSuccess, onFail) => {
  const { sheikhId, isAproved } = data;
  const path = `api/sheikh/changeSheikhStatus?sheikhId=${sheikhId}&isApproved=${isAproved}`;
  putResource(path, data, onSuccess, onFail);
};
export const getSheikhlecturesForApproveAttending = (
  data,
  onSuccess,
  onFail
) => {
  const path = `api/lecture/getSheikhlecturesForApproveAttending`;
  postResource(path, data, onSuccess, onFail);
};
export const approveAttending = (data, onSuccess, onFail) => {
  const { lectureId, isAttended } = data;
  const path = `api/lecture/approveSheikhAttending?lectureId=${lectureId}&isAttended=${isAttended}`;
  putResource(path, data, onSuccess, onFail);
};
export const getAllGroupsReport = (data, onSuccess, onFail) => {
  const path = `api/groups/allGroupsReport`;
  getResource(path, data, onSuccess, onFail);
};
export const getUserSurvey = (data, onSuccess, onFail) => {
  const { userId } = data;
  const path = `api/users/userSurvey?userId=${userId}`;
  getResource(path, onSuccess, onFail);
};
export const getAllGroups = (onSuccess, onFail) => {
  const path = `api/groups/allGroups`;
  getResource(path, onSuccess, onFail);
};
export const assignUserToGroup = (data, onSuccess, onFail) => {
  const path = "api/users/assignUserToGroup";
  postResource(path, data, onSuccess, onFail);
};
export const allNotInGroupUsers = (onSuccess, onFail) => {
  const path = `api/users/allNotInGroupUsers`;
  getResource(path, onSuccess, onFail);
};
export const addGroup = (data, onSuccess, onFail) => {
  const path = "api/groups/addGroup";
  postResource(path, data, onSuccess, onFail);
};
export const getAllActiveSheikhs = (onSuccess, onFail) => {
  const path = "api/sheikh/allSheikhs";
  getResource(path, onSuccess, onFail);
};
export const AssigntListOFUsersToGroup = (data, onSuccess, onFail) => {
  const path = "api/groups/AssigntListOFUsersToGroup";
  postResource(path, data, onSuccess, onFail);
};
export const ChangeGroupStatus = (data, onSuccess, onFail) => {
  const { groupId, isActive } = data;
  const path = `api/groups/changeGroupStatus?groupId=${groupId}&isActive=${isActive}`;
  putResource(path, data, onSuccess, onFail);
};
export const getActivelectures = (onSuccess, onFail, data) => {
  const path = "api/lecture/allActivelectures";
  postResource(path, data, onSuccess, onFail);
};
export const getUsersWithTheirLectures = (onSuccess, onFail, data) => {
  const path = "api/lecture/getUsersWithTheirLectures";
  postResource(path, data, onSuccess, onFail);
};
