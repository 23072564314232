import React from "react";
import { Button, Row, Table, DatePicker, Col } from "antd";
function Grid({
  mainTitle,
  subTitle,
  columns,
  dataSource,
  loading,
  rowKey = null,
  exportToExcel,
  changeDate,
  options = false,
  exportMe = false,
  searchFromTo = false,
  emptyTableText = {},
  onChange,
  // setPageNumber,
  // pageNumber,
  // recordsByPage = false,
}) {
  const { RangePicker } = DatePicker;
  // const handleChangePageNumber = (page) => {
  //   if (pageNumber && setPageNumber) {
  //     setPageNumber(page);
  //   }
  // };

  return (
    <div className="grid">
      <h3 className="f-36 fw-700">{mainTitle}</h3>
      <div className="m-4 content">
        <h4 className="f-18 fw-700">{subTitle}</h4>
        {options && (
          <Row justify={searchFromTo ? "between" : "end"} className="mb-2">
            {searchFromTo && (
              <Col span={18}>
                <div className="d-flex flex-1 justify-content-start">
                  <RangePicker
                    onChange={changeDate}
                    placeholder={["From date", "To date"]}
                    direction="rtl"
                  />
                </div>
              </Col>
            )}
            {exportMe && (
              <Col span={6} className="d-flex  justify-content-end">
                <Button
                  onClick={exportToExcel}
                  style={{
                    backgroundColor: "#BB55FA",
                    color: "white",
                    paddingInline: "20px",
                    borderRadius: "10px",
                    textAlign: "center",
                  }}
                >
                  Export to Excel
                </Button>
              </Col>
            )}
          </Row>
        )}
        <Table
          loading={loading}
          columns={columns}
          dataSource={dataSource}
          rowKey={(record) => record[rowKey]}
          rowClassName={(_, index) => index % 2 && "background-gray"}
          scroll={{ x: 600 }}
          locale={emptyTableText}
          onChange={onChange}
          pagination={{
            defaultPageSize: 7,
            responsive: true,
            showTotal: (t) => `Total : ${t}`,
            // defaultPageSize: recordsByPage ? recordsByPage : 7,
            // onChange: handleChangePageNumber,
            // current: pageNumber,
          }}
        />
      </div>
    </div>
  );
}

export default Grid;
