import { useState, useEffect, useRef } from "react";
import { exportToExcel, searchFunction } from "../../Helpers/helpers";
import { SearchOutlined, SettingTwoTone } from "@ant-design/icons";
import moment from "moment";
import NavBar from "../Common/NavBar/NavBar";
import SideBar from "../Common/SideBar/SideBar";
import { ChangeSheikhStatus, getAllSheikhs } from "../../Network/Network";
import { Button, DatePicker, Input, Space, Table } from "antd";
import Grid from "../Common/Grid/Grid";
function Sheikhs() {
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);

  const [mode, updateMode] = useState("");
  const [dataSource, updateDataSource] = useState([]);
  const [allDataSource, updateAllDataSource] = useState([]);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [rerender, updateRerender] = useState(false);
  const [searchValue, updateSearchValue] = useState("");
  const [searchText, updateSearchText] = useState("");
  const [searchedColumn, updateSearchedColumn] = useState("");

  let [dateFrom, setDateFrom] = useState(null);
  let [dateTo, setDateTo] = useState(null);
  let searchInput = useRef(null); // ref => { current: null }

  useEffect(() => {
    getAllSheikhsData();
    //eslint-disable-next-line
  }, [rerender, dateFrom, dateTo]);

  useEffect(() => {
    // setTableLoading(true);
    let searchColumns = ["firstName", "lastName", "phone", "noOfLectures"];
    let searchResult = searchFunction(
      allDataSource,
      searchValue,
      searchColumns
    );
    updateDataSource(searchResult);
    setFilteredDataSource(searchResult);
    // setTableLoading(false);
    //eslint-disable-next-line
  }, [searchValue]);

  const getAllSheikhsData = () => {
    getAllSheikhs(
      (success) => {
        updateDataSource(success.data);
        updateAllDataSource(success.data);
        setFilteredDataSource(success.data);
        setTableLoading(false);
      },
      (fail) => {},
      { from: dateFrom, to: dateTo }
    );
  };

  const Search = () => {
    updateRerender((prev) => !prev);
  };
  const changeSheikhStatusFunction = (sheikhId, isAproved) => {
    let data = {
      sheikhId,
      isAproved,
    };
    //console.log("data",data)
    ChangeSheikhStatus(data, (success) => {
      updateRerender(!rerender);
    });
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    updateSearchText(selectedKeys[0]);
    updateSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    updateSearchText("");
    updateSearchedColumn("");
    if (dataSource?.length) {
      setFilteredDataSource(dataSource);
    }
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              updateSearchText(selectedKeys[0]);
              updateSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex
        ? // <Highlighter
          // 	highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          // 	searchWords={[searchText]}
          // 	autoEscape
          // 	textToHighlight={text ? text.toString() : ""}
          // />
          text
        : text,
  });

  function changeDate(date) {
    if (date) {
      setDateFrom(date[0]);
      setDateTo(date[1]);
    } else {
      setDateFrom(null);
      setDateTo(null);
    }
  }

  const columns = [
    {
      title: "الاسم",
      key: "firstName",
      dataIndex: "firstName",
      ...getColumnSearchProps("firstName"),

      //sorter: (a, b) => a.firstName.length - b.firstName.length,
      sortDirections: ["descend"],
    },
    {
      title: "اللقب",
      key: "lastName",
      dataIndex: "lastName",
      ...getColumnSearchProps("lastName"),

      onFilter: (value, record) => record.lastName.indexOf(value) === 0,
      //sorter: (a, b) => a.lastName.length - b.lastName.length,
      sortDirections: ["descend"],
    },
    {
      title: "الهاتف",
      key: "phone",
      dataIndex: "phone",
      ...getColumnSearchProps("phone"),

      onFilter: (value, record) => record.phone.indexOf(value) === 0,
      //	sorter: (a, b) => a.phone - b.phone,
      sortDirections: ["descend"],
    },
    {
      title: "عدد المحاضرات",
      key: "noOfLectures",
      dataIndex: "noOfLectures",
      sorter: (a, b) => a.noOfLectures - b.noOfLectures,
      sortDirections: ["descend"],
    },
    // {
    // 	title: "Actions",
    // 	key: "isApproved",
    // 	dataIndex: "isApproved",
    // 	render: (isApproved, record) => {
    // 		return (
    // 			<div
    // 				className={`${isApproved ? "deactivateRecord" : "activateRecord"}`}
    // 				onClick={() => {
    // 					changeSheikhStatusFunction(record._id, !isApproved);
    // 				}}
    // 			>
    // 				{`${isApproved ? "DisApprove" : "Approve"}`}
    // 			</div>
    // 		);
    // 	},
    // },
  ];

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setFilteredDataSource(extra?.currentDataSource);
    updateDataSource(extra?.currentDataSource);
  };

  const handleExportToExcel = () => {
    let filteredData = [];
    filteredDataSource.map((shiekh) =>
      filteredData.push({
        Name: `${shiekh?.firstName} ${shiekh?.lastName}`,
        Phone: shiekh?.phone,
        "Number of lectures": shiekh?.noOfLectures,
      })
    );
    const data = filteredData;
    exportToExcel(data);
  };

  return (
    <div className="d-flex">
      <SideBar parent={"sheikhs"} />
      <div className="flex-1 section">
        <NavBar
          getSearchedValue={(value) => {
            updateSearchValue(value);
          }}
          disableSearchBar={mode === "add"}
        />
        {/* <div className="mx-4">
          <div className="d-flex justify-content-between">
            <h3 className="f-36 fw-700">
              المعلمون{" "}
              {mode === "add" ? "- Add" : mode === "edit" ? "- Edit" : ""}
            </h3>
          </div>
        </div> */}
        {/* <div className="d-flex justify-content-end flex-1 my-2">
          <RangePicker
            onChange={changeDate}
            placeholder={["From date", "To date"]}
          />
          <div>
            <Button onClick={Search}>Search</Button>
          </div>
        </div> */}
        {
          <Grid
            mainTitle={"المعلمون"}
            subTitle={"كل المعلمون"}
            columns={columns}
            dataSource={dataSource}
            loading={tableLoading}
            rowKey={"_id"}
            exportMe={true}
            options={true}
            searchFromTo={true}
            exportToExcel={handleExportToExcel}
            changeDate={changeDate}
            onChange={handleTableChange}
            search={Search}
            emptyTableText={{
              emptyText: "لا يوجد معلمون",
            }}
          />
        }
      </div>
    </div>
  );
}

export default Sheikhs;
