import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import NavBar from "../Common/NavBar/NavBar";
import SideBar from "../Common/SideBar/SideBar";
import Grid from "../Common/Grid/Grid";
import { getAllUsers, getUserSurvey } from "../../Network/Network";
import { exportToExcel, searchFunction } from "../../Helpers/helpers";
import moment from "moment";
function UsersPage() {
  const [tableLoading, setTableLoading] = useState(true);
  const [dataSource, updateDataSource] = useState([]);
  const [allDataSource, updateAllDataSource] = useState([]);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [rerender, updateRerender] = useState(false);
  const [searchValue, updateSearchValue] = useState("");
  let [dateFrom, setDateFrom] = useState(null);
  let [dateTo, setDateTo] = useState(null);
  const Navigate = useNavigate();

  useEffect(() => {
    // setTableLoading(true);
    const searchColumns = ["name", "mobile", "groupName"];
    const searchedDataSource = searchFunction(
      allDataSource,
      searchValue,
      searchColumns
    );
    updateDataSource(searchedDataSource);
    setFilteredDataSource(searchedDataSource);
    // setTableLoading(false);
    // eslint-disable-next-line
  }, [searchValue]);

  useEffect(() => {
    getAllUsersData();
    //eslint-disable-next-line
  }, [rerender, dateFrom, dateTo]);

  const getAllUsersData = () => {
    getAllUsers(
      (success) => {
        if (success?.isSuccess) {
          updateDataSource(success.data);
          updateAllDataSource(success.data);
          setFilteredDataSource(success.data);
          setTableLoading(false);
        }
      },
      (fail) => {},
      { from: dateFrom, to: dateTo }
    );
  };

  const getUserSurveyData = (userId) => {
    let data = {
      userId,
    };
    getUserSurvey(data, (success) => {
      Navigate("/survey", {
        state: {
          data: success.data,
          userId,
        },
      });
    });
  };
  const columns = [
    {
      title: "الاسم",
      key: "name",
      dataIndex: "name",
    },

    {
      title: "رقم الهاتف",
      key: "mobile",
      dataIndex: "mobile",
    },
    {
      title: " تاريخ الالتحاق",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (_, record) => {
        return (
          <span dir="ltr">
            {moment(record?.createdAt).format("YYYY-MM-DD HH:mm:ss")}
          </span>
        );
      },
    },
    {
      title: "المجموعة",
      key: "groupName",
      dataIndex: "groupName",
    },
    {
      title: "الاستبيان",
      key: "survey",
      dataIndex: "survey",
      render: (_data, record) => {
        return (
          <Button
            variant="primary"
            style={{ margin: "2px" }}
            onClick={(event) => {
              getUserSurveyData(record._id);
            }}
          >
            الاستبيان
          </Button>
        );
      },
    },
    // {
    // 	title: "Actions",
    // 	key: "isActive",
    // 	dataIndex: "isActive",
    // 	render: (isActive, record) => {
    // 		return (
    // 			<div
    // 				className={`${isActive ? "deactivateRecord" : "activateRecord"}`}
    // 				onClick={() => {
    // 					changeUserStatus(!isActive, record.id);
    // 				}}
    // 			>
    // 				{`${isActive ? "Deactivate" : "Activate"}`}
    // 			</div>
    // 		);
    // 	},
    // },
  ];

  function changeDate(date) {
    if (date) {
      setDateFrom(date[0]);
      setDateTo(date[1]);
    } else {
      setDateFrom(null);
      setDateTo(null);
    }
  }
  const handleTableChange = (pagination, filters, sorter, extra) => {
    setFilteredDataSource(extra?.currentDataSource);
    updateDataSource(extra?.currentDataSource);
  };
  const handleExportToExcel = () => {
    let filteredData = [];
    filteredDataSource.map((student) =>
      filteredData.push({
        Name: `${student?.firstName} ${student?.lastName}`,
        Phone: student?.mobile,
        "Group Name": student?.groupName,
        "Joining Date": moment(student?.createdAt).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      })
    );
    const data = filteredData;
    exportToExcel(data);
  };
  return (
    <div className="d-flex">
      <SideBar parent={"users"} />
      <div className="flex-1 section">
        <NavBar
          getSearchedValue={(value) => {
            updateSearchValue(value);
          }}
        />
        <Grid
          mainTitle={"الطلاب"}
          subTitle={"كل الطلاب"}
          columns={columns}
          dataSource={dataSource}
          loading={tableLoading}
          rowKey={"_id"}
          exportMe={true}
          options={true}
          searchFromTo={true}
          exportToExcel={handleExportToExcel}
          changeDate={changeDate}
          onChange={handleTableChange}
          // search={Search}
          emptyTableText={{
            emptyText: "لا يوجد طلاب",
          }}
        />
      </div>
    </div>
  );
}

export default UsersPage;
