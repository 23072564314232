import { useState, useEffect } from "react";
import { exportToExcel, searchFunction } from "../../Helpers/helpers";
import Grid from "../Common/Grid/Grid";
import NavBar from "../Common/NavBar/NavBar";
import SideBar from "../Common/SideBar/SideBar";

import {
  getSheikhlecturesForApproveAttending,
  approveAttending,
} from "../../Network/Network";
import moment from "moment";

function EndedLectures() {
  const [loading, setLoading] = useState(true);
  const [dataSource, updateDataSource] = useState([]);
  const [allDataSource, updateAllDataSource] = useState([]);
  const [rerender, updateRerender] = useState(false);
  const [searchValue, updateSearchValue] = useState("");
  let [dateFrom, setDateFrom] = useState(null);
  let [dateTo, setDateTo] = useState(null);

  useEffect(() => {
    getShiekhLectures();
    // eslint-disable-next-line
  }, [rerender, dateFrom, dateTo]);

  useEffect(() => {
    let searchColumns = [
      "description",
      "category",
      "approvedAttending",
      "sheikh",
      "from",
      "to",
      "notes",
    ];
    let searchResult = searchFunction(
      allDataSource,
      searchValue,
      searchColumns
    );
    updateDataSource(searchResult);
    setLoading(false);
    //eslint-disable-next-line
  }, [searchValue]);

  const getShiekhLectures = () => {
    getSheikhlecturesForApproveAttending(
      { from: dateFrom, to: dateTo },
      (success) => {
        if (success?.isSuccess) {
          updateDataSource(success.data);
          updateAllDataSource(success.data);
          setLoading(false);
        }
      }
    );
  };

  const approveAttendingFunction = (lectureId, isAttended) => {
    let data = {
      lectureId,
      isAttended,
    };
    approveAttending(data, (success) => {
      updateRerender(!rerender);
    });
  };
  const columns = [
    {
      title: "بداية المحاضرة",
      key: "from",
      dataIndex: "from",
      render: (_, record) => {
        return (
          <span dir="ltr">
            {moment(record?.from).format("YYYY-MM-DD HH:mm:ss")}
          </span>
        );
      },
    },
    {
      title: "نهاية المحاضرة ",
      key: "to",
      dataIndex: "to",
      render: (_, record) => {
        return (
          <span dir="ltr">
            {moment(record?.to).format("YYYY-MM-DD HH:mm:ss")}
          </span>
        );
      },
    },
    {
      title: "وصف المحاضرة",
      key: "description",
      dataIndex: "description",
    },
    {
      title: "المعلم",
      key: "sheikh",
      dataIndex: "sheikh",
    },
    {
      title: "ملاحظات",
      key: "notes",
      dataIndex: "notes",
    },
    {
      title: "الحضور",
      key: "approvedAttending",
      dataIndex: "approvedAttending",
      render: (approvedAttending, record) => {
        return (
          <div
            className={`${
              approvedAttending ? "deactivateRecord" : "activateRecord"
            }`}
            onClick={() => {
              approveAttendingFunction(record._id, !approvedAttending);
            }}
          >
            {`${approvedAttending ? "الغاء تأكيد الحضور" : "تأكيد الحضور"}`}
          </div>
        );
      },
    },
  ];

  const Search = () => {
    updateRerender((prev) => !prev);
  };

  function changeDate(date) {
    if (date) {
      setDateFrom(date[0]);
      setDateTo(date[1]);
    } else {
      setDateFrom(null);
      setDateTo(null);
    }
  }
  const handleTableChange = (pagination, filters, sorter, extra) => {};
  const handleExportToExcel = () => {
    let filteredData = [];
    dataSource.map((lec) =>
      filteredData.push({
        From: `${lec?.from.substring(0, 10)} ${lec?.from.substring(11, 16)}`,
        To: `${lec?.to.substring(0, 10)} ${lec?.to.substring(11, 16)}`,
        Description: lec?.description,
        Sheikh: lec?.sheikh,
        Notes: lec?.notes,
      })
    );
    const data = filteredData;
    exportToExcel(data);
  };
  return (
    <div className="d-flex">
      <SideBar parent={"endedlectures"} />
      <div className="flex-1 section">
        <NavBar
          getSearchedValue={(value) => {
            updateSearchValue(value);
          }}
        />
        {
          <Grid
            mainTitle={"المحاضرات"}
            subTitle={"كل المحاضرات"}
            columns={columns}
            dataSource={dataSource}
            loading={loading}
            rowKey={"_id"}
            exportMe={true}
            options={true}
            exportToExcel={handleExportToExcel}
            searchFromTo={true}
            changeDate={changeDate}
            search={Search}
            emptyTableText={{
              emptyText: "لا توجد محاضرات",
            }}
            onChange={handleTableChange}
          />
        }
      </div>
    </div>
  );
}

export default EndedLectures;
