import React, { useState } from "react";
import { Formik } from "formik";
import { Input, Spin, Col, Row } from "antd";
import { handleSignin } from "../../../Network/Network";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SIGNIN } from "../../../Store/authorization";
import lock from "../../../Assets/Icons/lock.svg";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import call from "../../../Assets/Icons/call.svg";
import FloatInput from "../../Common/FloatInput/FloatInput";
//import { getMessaging, getToken } from "firebase/messaging";
import { getJWTToken } from "../../../Network/Services";

function SignIn() {
	const [loading, updateLoading] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	//	const { token } = useSelector((state) => state.authorization);
	const handleSubmitSignin = (values) => {
		updateLoading(true);
		values.fireBaseToken = "token.token";
		handleSignin(
			values,
			(success) => {
				console.log(success)
				updateLoading(false);
				if (success.isSuccess) {
					dispatch(SIGNIN(success.data));
					getJWTToken(success.data.token);
					navigate("/users");
				}
			},
			(fail) => {
				updateLoading(false);
			}
		);
	};
	// useEffect(() => {
	// 	const messaging = getMessaging();
	// 	getToken(messaging, {
	// 		vapidKey:
	// 			"BD4Z6GbRXa2jUxTXDDV6thgn_BWjm34HzeXPNpyEx9Ea6KNtLclb10iSh_Gt2woAdqhAttCj-6Et8ed-XjJcZR8",
	// 	}).then((currentToken) => {
	// 		dispatch(ADDFIREBASETOKEN({ token: currentToken }));
	// 	});
	// 	// eslint-disable-next-line
	// }, []);

	return (
		<div className="signIn">
			<Row>
				<Col xs={24} md={12} className="solidArea"></Col>
				<Col className="d-flex flex-column flex-1 align-items-center justify-content-center">
					<Formik
						initialValues={{ phone: "", password: "" }}
						validate={(values) => {
							const errors = {};
							if (!values.phone) {
								errors.phone = "Required";
							} else if (
								!/^\d+$/i.test(values.phone)
							) {
								errors.email = "Invalid phone number";
							}
							if (!values.password) {
								errors.password = "Required";
							}
							return errors;
						}}
						onSubmit={(values, { setSubmitting }) => {
							handleSubmitSignin(values);
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
							/* and other goodies */
						}) => (
							<form
								onSubmit={handleSubmit}
								className={
									"mt-4 d-flex flex-column flex-1 align-items-center justify-content-center width-70"
								}
							>
								<h2 className="mainTitle mb-4">Hello!</h2>
								<FloatInput label="Phone" name="phone" value={values.phone}>
									<Input
										type="text"
										name="phone"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.phone}
										prefix={<img src={call} alt="call" />}
										className={`my-4 primary-input
                                     ${
																				errors.phone &&
																				touched.phone &&
																				`border  border-danger`
																			}`}
									/>
								</FloatInput>
								<FloatInput
									label="Password"
									name="password"
									value={values.password}
								>
									<Input.Password
										name="password"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.password}
										iconRender={(visible) =>
											visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
										}
										prefix={<img src={lock} alt="lock" />}
										className={`my-4 primary-input
                                     ${
																				errors.password &&
																				touched.password &&
																				`border  border-danger`
																			}`}
									/>
								</FloatInput>

								<div className="d-flex justify-content-between align-items-center f-14 width-100">
									<div>
										{/* <img
                      src={rembemberMe ? checkBoxFilled : checkBoxNormal}
                      alt='checkBox'
                      className="cursor-pointer"
                      onClick={() => {
                        updateRembemberMe(!rembemberMe)
                      }}
                    />
                    <span className='mainColor'>Remember Me</span> */}
									</div>
									{/* <Link to={"/forgotPassword"} className="mainColor">
										Forgot Password?
									</Link> */}
								</div>
								<button disabled={loading} className="primary_btn mt-4">
									{loading ? <Spin /> : "Login"}
								</button>
							</form>
						)}
					</Formik>
				</Col>
			</Row>
		</div>
	);
}

export default SignIn;
