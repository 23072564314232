import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Table from "react-bootstrap/Table";
import SideBar from "../Common/SideBar/SideBar";
import { getAllGroups, assignUserToGroup } from "../../Network/Network";
import { Button, Form } from "react-bootstrap";
export default function UserSurveyPage() {
  const [dataSource, updateDataSource] = useState([]);
  const [allDataSource, updateAllDataSource] = useState([]);
  // eslint-disable-next-line
  const [rerender, updateRerender] = useState(false);

  useEffect(() => {
    getAllGroups((success) => {
      updateDataSource(success.data);
      updateAllDataSource(success.data);
    });
    // eslint-disable-next-line
  }, [rerender]);

  const location = useLocation();
  // console.log("🚀 ~ file: UserSurvey.js:22 ~ UserSurveyPage ~ location", location.state?.userId)

  let groupId;
  const groupData = (id) => {
    groupId = id;
  };

  const assignUserTogroup = (e) => {
    if (!groupId) {
      e.preventDefault();
      alert("من فضلك اختار مجموعة ");
    } else {
      const data = { userId: location.state.userId, groupId };
      assignUserToGroup(data, (success) => {
        success?.isSuccess === true
          ? alert("تم الاضافة الي المجموعة بنجاح")
          : alert("حدث خطأ");
      });
    }
  };

  const dropDown = (datasource) => {
    return (
      <div className="my-3">
        <h2>اختر المجموعة</h2>
        <Form.Control
          style={{ width: "100%" }}
          as="select"
          required={true}
          onChange={(event) => {
            groupData(event.target.value);
          }}
        >
          <option selected disabled hidden value="">
            اختر المجموعة
          </option>
          {datasource.length > 0 &&
            datasource.map((item) => {
              return <option value={item._id}>{item.groupName}</option>;
            })}
        </Form.Control>
      </div>
    );
  };
  const setTable = () => {
    if (location.state?.data[0]) {
      return (
        <div
          className=" d-flex justify-content-center"
          style={{ width: "100%" }}
        >
          <Table
            striped
            bordered
            hover
            style={{ width: "50%", height: "100px" }}
          >
            <thead>
              <tr>
                <th>{location.state.data[0].name}</th>
                <th>الاسم </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{location.state.data[0].age}</td>
                <td>السن</td>
              </tr>
              <tr>
                <td>{location.state.data[0].levelId?.levelName}</td>
                <td>المستوي</td>
              </tr>
              <tr>
                <td>{location.state.data[0].amountOfMemorizedQuran}</td>
                <td>الكمية المحفوظة</td>
              </tr>
            </tbody>
          </Table>
        </div>
      );
    }
  };
  return (
    <div className="d-flex ">
      <SideBar parent={"users"} />
      <div className=" d-flex flex-column flex-1 justify-content-center align-items-center ">
        {setTable()}
        <br />
        <div className="d-flex flex-column justify-content-center align-items-center">
          {dropDown(dataSource)}
          <Button
            variant="primary"
            style={{ margin: "2px" }}
            onClick={(event) => {
              assignUserTogroup(event);
            }}
          >
            اضافة الى المجموعة
          </Button>
        </div>
      </div>
    </div>
  );
}
