import { useState } from "react";
import { Input, Dropdown, Menu, List } from "antd";
import noNotification from "../../../Assets/Icons/noNotification.svg";
import hasNotification from "../../../Assets/Icons/hasNotification.svg";
import divider from "../../../Assets/Icons/Divider.svg";
import arrow from "../../../Assets/Icons/arrow_drop_down_black.svg";
import magnifyingGlass from "../../../Assets/Icons/magnifying-glass.svg";
import defaultAvatar from "../../../Assets/Images/default-avatar.jpg";
import { useSelector } from "react-redux";
import { SIGNOUT } from "../../../Store/authorization";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useEffect } from "react";
function NavBar({ getSearchedValue, disableSearchBar }) {
  const [notifications, updateNotifications] = useState([
    {
      title: "New message",
      message: "Hello",
      isRead: true,
    },
    {
      title: "New message",
      message: "Hello",
      isRead: false,
    },
  ]);
  const { authorization } = useSelector((state) => state.authorization);
  const [searchValue, updateSearchValue] = useState("");

  const checkIfHasNot = notifications.every(
    (notification) => !notification.isRead
  );

  const dispatch = useDispatch();
  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <div
              onClick={() => {
                dispatch(SIGNOUT());
                localStorage.removeItem("token");
              }}
            >
              LogOut
            </div>
          ),
        },
      ]}
    />
  );

  useEffect(() => {
    getSearchedValue(searchValue);
    //eslint-disable-next-line
  }, [searchValue]);

  const notificationMenu = (
    <div
      id="scrollableDiv"
      style={{
        width: 400,

        border: "1px solid rgba(140, 140, 140, 0.35)",
      }}
    >
      <List
        dataSource={notifications}
        renderItem={(item) => (
          <List.Item
            className={`mb-2 ${!item.isDetailsSeen ? "not-seen" : ""}`}
            key={item.notificationId}
            onClick={() => {
              //readNotifications(item.notificationId);
            }}
          >
            <List.Item.Meta
              // avatar={<Avatar src={userAvatar} />}
              title={<div className="px-2">{item.title}</div>}
              onClick={() => {
                //handleClickONNotification(item);
              }}
              description={
                <div className="p-2">
                  <div>{item.message}</div>
                  <div className="text-end">
                    {moment(item.sendDate).format("LLL")}
                  </div>
                </div>
              }
            />
          </List.Item>
        )}
      />
    </div>
  );
  //console.log("authorization",authorization)
  const firstName = authorization.admin.fullName?.split(" ")[0];
  const handleUpdateNotReadState = () => {
    let notificationsList = [...notifications];
    notificationsList.forEach((notification, index) => {
      notificationsList[index].isRead = false;
    });
    updateNotifications(notificationsList);
  };
  const submitSearch = (e) => {
    //e.preventDefault();
    //if (searchValue.length) {
    getSearchedValue(searchValue);
    // alert(searchValue);
    //}
  };
  return (
    <div className="navBar d-flex justify-content-between">
      <form
        className={`flex-1 mx-2 ${disableSearchBar ? "invisible" : "visible"}`}
        onSubmit={submitSearch}
      >
        <Input
          type="text"
          className="searchField py-2"
          placeholder="Search"
          value={searchValue}
          allowClear={true}
          onChange={(e) => {
            updateSearchValue(e.target.value);
            // submitSearch()
          }}
          prefix={
            <button type="submit">
              <img src={magnifyingGlass} alt="magnifyingGlass" />
            </button>
          }
        />
      </form>
      <div className="d-flex align-items-center mx-4">
        <Dropdown overlay={notificationMenu} trigger={["click"]}>
          <img
            src={checkIfHasNot ? hasNotification : noNotification}
            alt="noNotification"
            className="ms-4 notificationIcon cursor-pointer"
            onClick={handleUpdateNotReadState}
          />
        </Dropdown>

        <img src={divider} alt="divider" className="mx-4" />
        <Dropdown overlay={menu} placement="bottom" arrow trigger={["click"]}>
          <div className="d-flex justify-content-between align-items-center secondaryColor cursor-pointer f-13">
            <span>{firstName}</span>
            <img src={arrow} alt="arrow" />
          </div>
        </Dropdown>
        <img src={defaultAvatar} alt="defaultAvatar" className="avatar mx-2" />
      </div>
    </div>
  );
}

export default NavBar;
