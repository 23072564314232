import React, { useState, useEffect } from "react";
import NavBar from "../Common/NavBar/NavBar";
import SideBar from "../Common/SideBar/SideBar";
import Grid from "../Common/Grid/Grid";
import { getAllGroupsReport, ChangeGroupStatus } from "../../Network/Network";
import { searchFunction } from "../../Helpers/helpers";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Space } from "antd";

function GroupsPage() {
  const [tableLoading, setTableLoading] = useState(true);
  const [dataSource, updateDataSource] = useState([]);
  const [allDataSource, updateAllDataSource] = useState([]);
  const [rerender, updateRerender] = useState(false);
  const [searchValue, updateSearchValue] = useState("");
  const Navigate = useNavigate();

  useEffect(() => {
    getGroupsData();
  }, []);

  useEffect(() => {
    // setTableLoading(true);

    const searchColumns = ["groupName", "sheikhName", "numberOfUsers"];
    const searchedDataSource = searchFunction(
      allDataSource,
      searchValue,
      searchColumns
    );
    updateDataSource(searchedDataSource);
    // setTableLoading(false);

    // eslint-disable-next-line
  }, [searchValue]);

  const getGroupsData = () => {
    getAllGroupsReport((success) => {
      if (success?.isSuccess) {
        updateDataSource(success.data);
        updateAllDataSource(success.data);
        setTableLoading(false);
      }
    });
  };

  const changeGroupStatusFunction = (isActive, groupId) => {
    let data = {
      groupId,
      isActive,
    };
    ChangeGroupStatus(data, (success) => {
      updateRerender(!rerender);
    });
  };
  const columns = [
    {
      title: "المجموعة",
      key: "groupName",
      dataIndex: "groupName",
    },
    {
      title: "المعلم",
      key: "sheikhName",
      dataIndex: "sheikhName",
    },
    {
      title: "عدد الطلاب",
      key: "numberOfUsers",
      dataIndex: "numberOfUsers",
    },
    {
      title: "Actions",
      key: "isActive",
      dataIndex: "isActive",
      render: (isActive, record) => {
        return (
          <Space size={20}>
            <Button
              //                            className={`${isActive ? "deactivateRecord" : "activateRecord"}`}
              onClick={() => {
                changeGroupStatusFunction(!isActive, record._id);
              }}
            >
              {`${isActive ? "الغاء تفعيل المجموعة" : "تفعيل المجموعة"}`}
            </Button>
            <Button
              variant="primary"
              onClick={(event) => {
                Navigate("/assignToGroup", {
                  state: {
                    groupId: record._id,
                    groupName: record.groupName,
                  },
                });
              }}
            >
              اضافة طلاب
            </Button>
            <Button
              variant="primary"
              onClick={(event) => {
                Navigate("/groupUsers", {
                  state: { groupId: record._id },
                });
              }}
            >
              طلاب المجموعة
            </Button>
          </Space>
        );
      },
    },
  ];
  return (
    <div className="d-flex">
      <SideBar parent={"groups"} />
      <div className="flex-1 section">
        <NavBar
          getSearchedValue={(value) => {
            updateSearchValue(value);
          }}
        />
        <Grid
          loading={tableLoading}
          mainTitle={"المجموعات"}
          subTitle={"كل المجموعات"}
          columns={columns}
          dataSource={dataSource}
          emptyTableText={{
            emptyText: "لا توجد مجموعات",
          }}
        />
        <Button
          variant="primary"
          style={{ margin: "2px" }}
          onClick={(event) => {
            Navigate("/addGroup");
          }}
        >
          اضافة مجموعة جديدة
        </Button>
      </div>
    </div>
  );
}

export default GroupsPage;
