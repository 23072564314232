import { useState } from "react";
import { Col, Input, Row, Spin } from "antd";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { sendVerificationCode } from "../../../Network/Network";
import { useNavigate } from "react-router-dom";
import FloatInput from "../../Common/FloatInput/FloatInput";
import mail from "../../../Assets/Icons/mail.svg";

function ForgotPassword() {
	const [loading, updateLoading] = useState(false);
	const navigate = useNavigate();
	const handleSendCode = (values) => {
		updateLoading(true);
		sendVerificationCode(
			{ email: values.email },
			(success) => {
				updateLoading(false);
				if (success.success) {
					navigate(`/OTP?email=${values.email}`);
				}
			},
			(fail) => {
				updateLoading(false);
			}
		);
	};

	return (
		<div className="forgotPassword">
			<Row>
				<Col xs={24} md={12} className="solidArea"></Col>
				<Col
					xs={24}
					md={12}
					className="d-flex flex-column flex-1 align-items-center justify-content-center"
				>
					<Formik
						initialValues={{ email: "" }}
						validate={(values) => {
							const errors = {};
							if (!values.email) {
								errors.email = "Required";
							}
							return errors;
						}}
						onSubmit={(values, { setSubmitting }) => {
							handleSendCode(values);
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
							/* and other goodies */
						}) => (
							<form
								onSubmit={handleSubmit}
								className={
									"mt-4 d-flex flex-column flex-1 align-items-center justify-content-center width-60"
								}
							>
								<h3 className="mainTitle mb-2 fw-800">Forget Password!</h3>
								<div className="f-25 text-center enterEmailNumber">
									Please enter your email to get a confirmation code
								</div>
								<FloatInput label="Email" name="email" value={values.email}>
									<Input
										type="email"
										name="email"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.email}
										prefix={<img src={mail} alt="mail" />}
										className={`my-4 primary-input
                                     ${
																				errors.email &&
																				touched.email &&
																				`border  border-danger`
																			}`}
									/>
								</FloatInput>
								<button disabled={isSubmitting} className="primary_btn my-2">
									{loading ? <Spin /> : "Send"}
								</button>
								<div className="mainColorDarker">
									Remember Password?
									<Link to={"/"} className="mainColor underline">
										Login
									</Link>
								</div>
							</form>
						)}
					</Formik>
				</Col>
			</Row>
		</div>
	);
}

export default ForgotPassword;
