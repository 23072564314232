import React from 'react'
import SideBar from '../Common/SideBar/SideBar'

function Dashboard() {
    return (
        <div className='d-flex'>
            <SideBar parent={'dashboard'} />
        </div>
    )
}

export default Dashboard