import React, { useState, useEffect } from "react";
import { useLocation,useNavigate } from "react-router-dom";
import SideBar from "../Common/SideBar/SideBar";
import { allNotInGroupUsers, AssigntListOFUsersToGroup } from "../../Network/Network";
import { Button } from "react-bootstrap";
import { Input } from "antd";
import { toastSuccess } from "../../Helpers/toasters";
import Select from "react-select";
export default function AssignToGroup() {
    const [dataSource, updateDataSource] = useState([]);
    // eslint-disable-next-line
    const [rerender, updateRerender] = useState(false);
    const [loading, updateLoading] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState();
const Navigate=useNavigate()
    useEffect(() => {
        allNotInGroupUsers((success) => {
            updateDataSource(success.data);
        });
        // eslint-disable-next-line
    }, [rerender]);

    const location = useLocation();

    const handleAssignTOGroup = (e) => {
        if(!selectedOptions||selectedOptions.length===0){
            e.preventDefault();
            alert("من فضلك اختار طالب ")
        }else{
            e.preventDefault();
            updateLoading(true);
            let data = {
                groupId:location.state.groupId,
                students: selectedOptions&&selectedOptions.length>0?selectedOptions.map(a => a.value):[]
          
            };
            AssigntListOFUsersToGroup(
                data,
                (success) => {
                    updateLoading(false);
                    if (success.isSuccess) {
                        toastSuccess(success.message);
                        setSelectedOptions([]);
                        Navigate("/groups")
                    }
                },
                (fail) => {
                    updateLoading(false);
                }
            );
        }
    };
    function handleSelect(data) {
            setSelectedOptions(data);
      }
      const dropDown=()=>{
        return (
            <div className="dropDownSelect">
              <h2>اختر الطالب</h2>
              <div className="dropdown-container">
                <Select
                  required={true}
                  options={dataSource}
                  placeholder="اختر الطالب"
                  value={selectedOptions}
                  onChange={handleSelect}
                  isSearchable={true}
                  isMulti
                />
              </div>
            </div>
          );
      }
    return (
        <div className="d-flex">
            <SideBar parent={"groups"} />
            <div className="flex-1 section">
                <form onSubmit={handleAssignTOGroup} className="homeSection  p-2 mx-4">
                    <div className="d-flex align-items-center">
                        <div className="f-18 mx-2 fw-700 w-20">اسم المجموعة </div>
                        <Input
                        disabled
                            type="text"
                            placeholder="اسم المجموعة"
                            className="homeSection-input"
                            value={location.state.groupName}
                        />
                    </div>
                    <div >
                        {dropDown()}
                    {/* <div className="f-18 mx-2 fw-700 w-20">الطلاب</div>

                        <Form.Control
                            //style={{width:"50%"}}
                            as="select"
                            onChange={(event) => {
                                //let obj =event.target.value
                                //console.log("event",obj)
                                updateStudentId(event.target.value[0])
                                updateStudentName(event.target.value[1])
                            }
                            }
                        >
                            <option selected disabled hidden value="">
                                اختر الطالب
                            </option>
                            {dataSource.length > 0 &&
                                dataSource.map((item) => {
                                    return (
                                        <option value={item._id} >
                                            {item.fullName}
                                        </option>
                                    );
                                })}
                        </Form.Control>
                        <Button
                            variant="primary"
                            style={{ margin: "2px" }}
                            onClick={(event) => { fillStudentArray(studentId) }}
                        >
                            اضافة الى المجموعة
                        </Button> */}
                    </div>
                    <div className="d-flex justify-content-end my-2">
                        <Button
                            className="primary_btn"
                            loading={loading}
                            type="primary"
                            htmlType="submit"
                        //	disabled={!phone || !password || !fullName}
                        >
                            تأكيد
                        </Button>
                    </div>
                </form>
                <br />
            </div>

        </div>
    );
}
